
.top-bar{
    background: #2E4936;
    height: 50px;
    color: #fff;
    display: inline-flex;
    align-items: center;
}
.top-bar-left,
.top-bar-right{
    padding: 0px 15px;
}
.top-bar-left ul{
   display: flex;
   flex-wrap: wrap;
}
.top-bar-left ul li:nth-child(2){
    margin-left: 45px;
 }
.top-bar-right ul li{
    display: inline-block;
    margin-left: 20px;
 }
 .top-bar-right ul li:first-child{
    margin-left: 0px;
 }
 .top-bar-right ul{
    display: flex;
    justify-content: flex-end;
 }
 .top-bar .icon{
    color: #fff;
    font-size: 16px;
    padding-right: 5px;
 }
 .top-bar ul li a{
    color: #fff;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
 }
 /* navbar */
 #navbar{
  background-color: #F7F7F7;
  border-bottom: 1px solid #CCCCCC;
  padding: 6px 0px;
 }
 menu{
    padding: 0;
    margin: 0;
 }
 #navbar nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
 }
 .logo{
    width: 100px;
    max-width: 100%;
 }
 .logo img{
    width: 100%;
 }
 .main-menu{
    display: flex;
    flex-wrap: wrap;
 }
 .main-menu li{
    margin-left: 40px;
    position: relative;
 }

 .main-menu li a{
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: #000000;
    letter-spacing: 0;
    text-transform: capitalize;
    padding: 30px 0px;
 }
 .main-menu li .active{
    color: #258F67;
 }
.menuBtn{
    display: none;
}

@media (max-width: 1199.98px) { 
    .top-bar ul li a{
        font-size: 14px;
    }
    .main-menu li a{
        font-size: 14px;
    }
}
@media (max-width: 992px) { 
    .hero-left h1{
        font-size: 35px;
        margin-bottom: 10px;
    }
    .hero-left{
        text-align: center;
        margin-top: 30px;
    }
    .hero-right {
        text-align: center;
    }
    .top-bar-left ul,
    .top-bar-right ul
    {
        justify-content: center;
    }
    .home .hero .col-lg-6:last-child{
        order: -1;  
    }
    .home .hero{
        padding-top: 50px;
    }
   .product-ct-rw .col-lg-3{
       width: 100%;
       flex: 0 0 100%;
     max-width: 100%;
    }
    .prodct-ct-contnt{
        text-align: center;
        margin-bottom: 50px;
    }
    .prodct-ct-contnt h2,
    .prodct-ct-contnt p{
 text-align: center;
    }
    .product-ct-rw  .col-lg-9{
        width: 100%;
        flex: 0 0 100%;
      max-width: 100%;
    }
    .best-seller-in{
        margin-bottom: 50px;
    }
    .choose-us-in{
        text-align: center;
    }
    .choose-us-in img{
        border-radius: 30px;
        overflow: hidden;
    }
    .choose-us-box{
        flex-direction: column;
    }
    .choose-us-box .contnts{
        width: calc(100% - 0px);
        padding-left: 0px;
        text-align: center;
    }
    .choose-us-box .contnts h6{
        text-align: center; 
    }
    .choose-us-box-icn {
        width: auto;
    }
    .choose-us{
        padding-bottom: 80px;
    }
    .business .row{
         flex-direction: column;
         align-items: center;
    }
    .business .row .col-lg-4{
        margin-bottom: 20px;
    }
}
@media (max-width: 768px){
    .top-bar-right{
        margin-top: 5px;
    }
    .top-bar-left ul li{
        font-size: 13px;
    }
    .logo{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .menuBtn{
    display: inline-block;
    cursor: pointer;
    border: 0px;
    outline: none;
    background: #fff;
    color: #258F67;
}
#navbar nav{
    flex-wrap: wrap;
}
.logo img {
    width: 70px;
}
menu {
    width: 100%;
    display: none;
}
menu .main-menu{
    width: 100%; 
    flex-direction: column;
}
menu .main-menu li{
    margin-left: 0px;
}
menu .main-menu li a{
    display: inline-block;
    padding: 10px 0px;
    width: 100%;
}
.active menu{
    display: block;
}
}