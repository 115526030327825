@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
    margin: 0px;
    box-sizing: border-box;
}
h1,h2,h3,h4,h5,h6,p{
    margin: 0;
    padding: 0;
}
ul{
    margin: 0;
    padding: 0;
}
li{
    list-style: none;
}
a{
    text-decoration: none !important; 
    transition: .3s;
    cursor: pointer;
}
.empty{
    font-family: "Roboto", sans-serif;
    font-weight: 300; /* light */
    font-weight: 400; /* regular */
    font-weight: 500; /* medium */
    font-weight: 700; /* bold */
    font-weight: 900; /*extra bold */
    font-style: normal;
}
.w-100{
    width: 100%;
}
.row{
    margin: 0px -15px;
}

 /* hero */
 .hero{
    padding: 100px 0px;
    background-image: url("./assets/imsges/leaves.png");
    background-position: right;
    background-repeat: no-repeat;
 }
 .hero-in{
    padding: 0px 15px;
 }
.hero-left h5{
    font-size: 20px;
    color: #6D965E;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 20px;
    margin-top: 7px;
  }
  .hero-left h1{
    font-size: 66px;
    color: #444434;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 28px;
  }
  .hero-left p{
    font-size: 16px;
    line-height: 20px;
    color: #7D7D6C;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin-bottom: 38px;
  }
  .read-bt{
    background-color: #6D965E;
    padding: 14px 20px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-transform: capitalize;
    display: inline-block;
    align-items: center;
    justify-content: center;
  }
  .shop-bt{
    font-size: 16px;
    font-weight: 500;
    color: #6D965E;
    text-transform: capitalize;
    display: inline-block;
    align-items: center;
    background-color: transparent;
    justify-content: center;
    margin-left: 69px;
  }
  .hero-right{
    text-align: right;
  }
  .hero-right img{
    border-radius: 30px;
  }
  .hero-right{
    position: relative;
  }
  .reviews-box{
     position: absolute;
     content: "";
     display: flex;
     align-items: center;
     justify-content: space-between;
  }
   /*  .hygienic{
    top: 138px;
    left: -97px;
    background-color: #2E4936;
    width: 318px;
    height: 88px;
    border-radius: 7px;
    padding: 10px;
  }
.vitamins{
    top: 70%;
    right: 0;
    background-color: #FFCA41;
    width: 318px;
    height: 88px;
    border-radius: 7px;
    padding: 10px;
  } */
  .reviw-hd{
    color: #6D965E;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
  }
  .reviw-left{
    padding: 10px;
    background-color: #fff;
    border-radius: 7px;
  }
  .vitmn-img{
    padding: 6px;
    background-color: #2E4936;
    border-radius: 7px;
  }
  .reviw-center{
    margin-left: 15px;
  }
  .reviw-right span{
    padding: 15px 10px;
    background-color: #90F2B7;
    font-weight: 500;
    font-size: 20px;
    border-radius: 7px;
  }
  .reviw-center span{
    font-weight: 500;
    font-size: 14px;
    color: #fff;
  }
  .reviw-price9{
    padding: 10px 15px;
    background-color: #fff;
    font-weight: 500;
    font-size: 20px;
    border-radius: 7px;
  }
  /* product-ct */
  .product-ct{
    position: relative;
    padding-bottom: 80px;
}
  .product-ct-bg{
    position: absolute;
    left: 13%;
    top: -40%;
    z-index: -1;
}
.product-ct-in{
    padding: 0px 15px;
    text-align: center;
}
.product-ct-rw{
    display: flex;
    align-items: center;
}
.product-ct-in-img{
    height: 304px;
    border-radius: 30px; 
    overflow: hidden;
}
.product-ct-in img{
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}
.prodct-ct-contnt h2{
    font-size: 48px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #444434;
    position: relative;
    text-align: left;
}
.prodct-ct-contnt h2::before{
    position: absolute;
    content: "";
    background-color: #90F2B7;
    height: 7px;
    width: 173px;
    top: 40px;
    z-index: -1;
}
.prodct-ct-contnt p{
    margin-top: 15px;
    font-size: 16px;
    line-height: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    text-align: left;
    color: #7D7D6C;
}
.product-ct-in h5{
    margin-top: 21px;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #2E4936;
}
/* -------- brand ----- */
.brand .owl-nav{
    display: none;
}
.brand{
    padding: 80px 0px;
    background: linear-gradient(to bottom, rgba(255,248,231,1) 0%,rgba(255,248,231,0.58) 42%,rgba(255,248,231,0) 100%);
}
.brand h3{
    color: #2E4936;
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
    margin-bottom: 56px;
}
/* .brand .item{
    width: 200px;
} */
.brand .item img{
  width: 100%;
}
#brand-info{
    padding: 0px 30px;
}
/* best-seller */
.best-seller{
    padding: 20px 0px;
    text-align: center;
    background: linear-gradient(to bottom, rgba(255,248,231,0) 0%,rgba(255,248,231,0) 42%,rgba(255,248,231,0) 100%);
}
h2{
    font-size: 48px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #444434;
}
p{
    font-size: 16px;
    line-height: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #7D7D6C;
}
.best-seller-txt{
    margin: 20px 0px 36px 0px;
}
.best-seller-in{

    text-align: left;
}
.best-seller-in-img img{
    width: 100%;
    height: 100%;
    border-radius: 30px;
    overflow: hidden;
}
.best-selle-fst{
    border: 1px solid #CCCCCC;
}
.best-seller-in-img{
    border-radius: 30px;
    height: 304px;
    object-fit: cover;
}
.best-seller-in h5{
    margin: 27px 0px 24px 0px;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #2E4936;
}
.best-seller-in .price{
    margin-bottom: 25px;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #444434;
}
.best-seller-in a{
    border: 1px solid #000000;
    border-radius: 7px;
    padding: 10px 14px;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #444434;
    text-transform: capitalize;
    text-align: center;
    display: inline-block;
}
.best-seller-in a:hover{
    background: #258F67;
    color: #fff;
    border: 1px solid #258F67;
}
.view-bt{
    margin-top: 57px;
    font-size: 16px;
    font-weight: 500;
    color: #6D965E;
    text-transform: capitalize;
    display: inline-block;
    align-items: center;
    outline: none;
    border: none;
    background-color: transparent;
}
/* gallery */
.gallery{
    padding: 80px 0px;
    text-align: center;
    overflow: hidden;
}
.gallery-in-Img{
    height: 541px;
}
.gallery-in img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.gallery-txt{
    margin: 20px 0px 60px 0px;
}
.gallery-in{
    cursor: pointer;
    position: relative;
}
.overlay{
    position: absolute;
    content: "";
    width: 50px;
    height: 50px;
    background: #000000c6;
    backdrop-filter: blur(70px);
    border-radius: 50%;
    display: inline-block;
    align-items: center;
    justify-content: center;
    z-index: 1;
    font-size: 30px;
    color: #fff;
    left: 40%;
    top: 45%;
    transform: scale(0);
    transition: all ease .3s;
    opacity: 0;
}
.gallery-in:hover .overlay{
    opacity: 1;
    transform: scale(1);
}
/* why choose us */
.choose-us{
    position: relative;
    padding: 20px 0px 100px 0px;
}
.bg-leave{
    position: absolute;
    content: "";
    top: -10%;
    right: 12%;
    z-index: -1;
}
.choose-us-in{
    padding: 0px 15px;
}
.choose-us-in h2{
    margin: 40px 0px 54px 0px;
}
.choose-us-box-icn{
    width: 60px;
}
.choose-us-box{
    display: flex;
    align-items: center;
    border-radius: 30px;
    background-color: transparent;
    padding: 20px;
    margin-bottom: 20px;
    transition: .3s;
}
.choose-us-box:hover{
    background-color: #90F2B7;
}
.choose-us-box .contnts{
    padding-left: 60px;
    width: calc(100% - 60px);
}
.choose-us-box .contnts h6{
      color: #444443;
      font-size: 24px;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      text-align: left;
      text-transform: capitalize;
      margin-bottom: 16px;
}
.hero-right img{
    max-width: 100%;
}
.choose-us-in img{
    max-width: 100%;
}
@media (max-width: 1199.98px) { 
    .business-in h4{
        font-size: 16px;
    }
    .choose-us-box .contnts{
        padding-left: 20px;
    }
    .gallery-in-Img{
        height: 400px;
    }
    .best-seller-in a{
        font-size: 14px;
    }
    .best-seller-in .price{
        font-size: 16px;
    }
    .best-seller-in h5{
        font-size: 16px;
    }
    .best-seller-in-img{
        height: 250px;
    }
    h2{
        font-size: 36px;
    }
    .product-ct-in-img{
        height: 200px;
    }
    .prodct-ct-contnt p{
        font-size: 14px;
        line-height: 25px;
    }
    .prodct-ct-contnt h2{
        font-size: 30px;
    }
    .hero-left h1 {
        font-size: 50px;
    }
    .hero-left p{
        font-size: 14px;
        line-height: 25px;
    }

}
@media (max-width: 430px){
    section{
        overflow: hidden;
    }
    header{
        overflow: hidden;
    }
    .brand .item{
        width: auto !important;
    }
}

@media (max-width: 768px) { 
    .hero-left h1 {
        font-size: 30px;
    }
.home .hero .col-sm{
    width: 100%;
    flex-basis: auto;
}
.read-bt {
    padding: 11px 13px;
    font-size: 14px;
}
.prodct-ct-contnt h2::before{
    height: 4px;
    top: 20px;
}
.home .hero {
    padding-bottom: 50px;
}
.product-ct{
    padding-bottom: 50px;
}
.brand h3{
    font-size: 30px;
}
h2 {
    font-size: 30px;
}
.best-seller-in-img {
    height: auto;
}
.view-bt{
    margin-top: 0px;
}
.gallery {
    padding: 50px 0px;
}
.choose-us-box-icn{
    margin-bottom: 20px;
}
.choose-us{
    padding-bottom: 50px !important;
}
.business{
    padding: 50px 0px !important;
}
.testimonials{
    padding: 50px 0px !important;
}
.tstimnals-in{
    padding: 25px !important;
}
}

/*me*/
.activityLoader{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ffffffc4;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
  }
 .login_bt-fg{
    padding: 4px 20px;
    margin-top: -6px;
    border: 1px solid transparent;
    color: #fff !important;
    outline: none;
    background: #2E4936;
    font-size: 22px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 2px;
    text-transform: capitalize;
    text-align: center;
  }
  .login_bt-fg:hover{
    background: #4caf50;
    color: #fff;
  } 

