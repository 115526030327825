/* footer */
footer{
    padding: 74px 0px 15px 0px;
    background-color: #2E4936;
}
.ft-topbar{
    text-align: center;
}
.ft-topbar .ft-logo{
  margin-bottom: 34px;
}
.ft-topbar .ft-logo img{
    padding: 20px 25px;
    border-radius: 15px;
    background-color: #fff;
  
}
.ft-topbar ul{
    display: flex;
    flex-wrap: wrap;
    gap: 146px;
    justify-content: center;
    margin-bottom: 28px;
}
.ft-topbar ul li a{
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}
.copright{
    margin-top: 2.49rem;
    padding-top: 1rem;
    border-top: 1px solid #d9d9d93f;
}
.copright p{
   text-align: center;
   padding: 10px 0px;
   color: #fff;
   font-size: .89rem;
}
.ft-center-in2 h5,
.ft-center-in h5{
    font-size: 16px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0.4px;
  }
  .line{
    margin: 2rem 0px;
  }
  .ft-center-in .footer_text{
    font-size: 14.5px;
    color: #868493;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin-top: 10px;
    line-height: 18px;
    margin-bottom: 33px;
  }
  .ft-center-in p{
    font-size: 14.5px;
    color: #868493;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin-top: 10px;
    line-height: 18px;
    margin-bottom: 33px;
  }
  .ft-center-in2 h5{
    margin-bottom: 20px;
  }
  address{
   margin: 0px;
  }
  .vendrs p,
  .users p{
    margin-bottom: 5px;
    font-size: 16px;
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .vndrs-usrs{
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
  }
  .social-item{
    padding-left: 60px;
  }
  .social-item ul li{
    margin-top: 16px;
  }
  .social-item ul li:first-child{
    margin-top: 0px;
  }
  .social-item ul li a{
    font-size: 13.5px;
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .add-ft{
    font-size: 14.5px;
    color: #868493;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    line-height: 19px;
  }
  .tel-ft,
  .mail-ft{
    font-size: 14.5px;
    color: #868493;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    line-height: 19px;
    display: inline-block;
    margin-top: 14px;
  }
  @media (max-width: 1199.98px) { 
    .ft-topbar ul li a{
        font-size: 14px;
    }
    .main-menu li a{
        font-size: 14px;
    }
    .ft-center-in2 h5, .ft-center-in h5{
      font-size: 14px;
    }
}
@media (max-width: 992px) { 
.ft-topbar ul{
  gap: 30px;
}
.ft-topbar ul {
  gap: 22px;
}
}