.contact-top-bar{
    padding: 100px 0px;
  }
  .contact-box{
    padding: 0px 15px;
  }
  .contact-bx-in{
    height: 242px;
    max-width: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 30px 2px #00000043;
    padding: 36px 30px;
  }
  .contact-bx-in .comon-icn{
    border-radius: 50px;
    box-shadow: 0px 0px 30px 2px #90f2b7;
    display: inline-block;
    text-align: center;
    background: #2e4936;
    width: 45px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .contact-bx-in .comon-icn .telphn-fa{
    transform: rotate(-30deg);
  }
  .comon-icn i{
    font-size: 18px;
    color: #fff;
  }
  .contact-box-cnt h3{
    font-size: 24px;
    color: #2E4936;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    margin: 27px 0px 25px 0px;
  }
  .contact-box-cnt a{
    font-size: 16px;
    color: #444434;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  /* from-info */
  .from-info{
    padding: 20px 0px 100px 0px;
  }
  .from-info-in{
    padding: 0px 15px;
  }
  .map-info{
    height: 630px;
  }
  .map-info iframe{
    width: 100%;
    height: 100%;
    object-fit: cover;
    outline: none;
    border: none;
  }
  .from-info-in-right h3{
    font-size: 32px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #2E4936;
  }
  .from-info-in-right p{
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #444434;
    margin: 25px 0px;
  }
  .from-info-group-in{
    margin-bottom: 20px;
  }
  .input0-fg{
    min-height: 57px;
    padding: 6px 16px;
    width: 100%;
    max-width: 100%;
    border: 1px solid transparent;
    transition: all .3s;
    color: #2e4936;
    outline: none;
    background: #E9EEF4;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    border-radius: 0px;
  }
  .txtarea-fg{
    min-height: 200px;
    padding: 6px 16px;
    width: 100%;
    max-width: 100%;
    border: 1px solid transparent;
    transition: all .3s;
    color: #2e4936;
    outline: none;
    background: #E9EEF4;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    border-radius: 0px;
    margin-bottom: 33px;
  }
  .from-info-group .bt-fg{
    padding: 12px 40px;
    border: 1px solid transparent;
    transition: all .3s;
    color: #fff;
    outline: none;
    background: #2E4936;
    font-size: 22px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 2px;
    text-transform: capitalize;
    text-align: center;
    transition: 0.3s;
  }
  .bt-fg:hover{
    background: #4caf50;
    color: #fff;
  }

  @media (max-width:992px) { 
    .from-info{
      padding-top: 0px;
    }
    .txtarea-fg{
      resize: none;
      font-size: 15px;
    }
    .input0-fg{
      font-size: 15px;
    }
    .map-info {
      height: 430px;
  }
    .from-info-group .bt-fg{
      font-size: 14px;
    }
    .from-info .col-lg-6:last-child{
        order: -1;
        margin-bottom: 50px;
      
    }
    .from-info .col-lg-6{
      flex: 0 0 100%;
      max-width: 100%;
    }
    .contact-top-bar{
      padding: 50px 0px;
    }
    .contact-bx-in{
      box-shadow: 0px 0px 30px 2px rgb(0 0 0 / 12%);
    }
    .contact-top-bar .col-lg-4 {
      flex: 0 0 100%;
        max-width: 100%;
    }
    .contact-box{
      margin-bottom: 30px;
    }
  }
