#tstimnals .swiper-horizontal{
padding-bottom: 20px;
padding-top: 80px;
}
/* testimonials */
.testimonials{
    padding: 90px 0px 80px 0px;
}
.testimonials h2{
    text-align: center;
    margin-bottom: 107px;
}
#tstimnals{
    padding: 0px 15px;
    position: relative;
}
.testmnials-img{
    position: absolute;
    content: "";
    top: -73px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;

}
.testmnials-img img{
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 100%;
}
.tstimnals-in{
    width: 730px;
    /*height: 346px;*/
    box-shadow: 0px 0px 20px 2px #0000003d;
    border-radius: 30px;
    text-align: center;
    padding: 50px 100px;
    margin: 0 auto;
    max-width: 100%;
}
.tstimnals-in h5{
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 30px;
    text-transform: capitalize;
    color: #000000;
}
.tstimnals-in h6{
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin-bottom: 26px;
    text-transform: uppercase;
    color: #444434; 
}
.tstimnals-in p{
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 26px;
    color: #444434; 
}
@media (max-width: 768px) {
    .ft-center-in p{
        font-size: 14px;
        line-height: 25px;
    }
    .tel-ft, .mail-ft{
        font-size: 14px;
    }
    .add-ft{
        font-size: 14px;
    }
    .social-item {
        padding-left: 0px;
        margin-top: 20px;
    }
    .ft-topbar .ft-logo img{
        width: 100px;
        padding: 13px;
    }
    
    .business-in{
        flex-direction: column;
    }
    .business-in h4{
       text-align: center;
       margin-top: 15px;
    }
    .business-in h4{
        font-size: 16px;
    }
    .testimonials h2{
        margin-bottom: 0px;
    }
    .testimonials{
        padding-bottom: 0px !important;
    }
    #tstimnals .swiper-horizontal{
        padding-top: 0px;
    }
    .tstimnals-in{
        box-shadow: none;
        height: auto;
    }
    .testmnials-img{
        position: relative;
        top: auto;
    }
}