.banner {
    background-color: #90F2B7;
    padding: 140px 0;
 }
 .banner .container{
    position: relative;
 }
 .banner .inner-text{
    text-align: center;          
 }
 .banner .inner-text p{
    font-size: 16px;
    line-height: 20px;
    padding-top: 35px;
    padding-bottom: 26px;
 }
 .banner .inner-text a{
    font-size: 16px;
    color: #2E4936 ;
    display: inline-block;
 }
 .banner .inner-text span{
    font-size: 17px;
    display: inline-block;
 }
 .banner .inner-image-left img{
    width: 290px;
    position: absolute;
    top: -24px;
    left: 16px;
 }
 .banner .inner-image-right img{
    width: 290px;
    position: absolute;
    top: 0;
    right: 15px;
 }
 @media (max-width: 1200px) {
   .banner {
      background-color: #90F2B7;
      padding: 60px 0;
   }
  
 }
