.about{
    padding: 80px 0 170px 0;
  }
  .inner-text h1{
    color: #444434;
    font-size: 48px;
    font-weight: 600;
  }
  .about .row{
    align-items: center;
  }
  .about .image img{
    width: 483px;
  }
  .about .text h6{
    font-size: 20px;
    color: #6D965E;
  }
  .about .text h2{
    font-size: 48px;
    line-height: 52px;
    padding-top: 27px;
    padding-bottom: 30px;
  }
  .about .text p{
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 48px;
  }
  .about .text a{
    padding: 18px;
    background-color: #6D965E;
    border-radius: 3px;
    color: #fff;
  }
  .about .text{
    padding-left: 50px;
  }
  .choose{
    padding: 80px 0 160px 0;
    background-color: #FFFCF5;
 }
 .choose .row{
   align-items: center;
 }
 .choose .image img{
   width: 539px;
   height: 480px;
   border-radius: 20px;
 }
 .choose .text h6{
   font-size: 20px;
   color: #6D965E;
 }
 .choose .text h2{
   font-size: 48px;
   line-height: 52px;
   padding-top: 40px;
   padding-bottom: 30px;
 }
 .choose .text p{
   font-size: 16px;
   line-height: 22px;
   padding-bottom: 48px;
 }
 .choose .text a{
   padding: 18px;
   background-color: #6D965E;
   border-radius: 3px;
   color: #fff;
 }
 .choose .text{
   padding-left: 50px;
 }
 .image img{
  max-width: 100%;
 }
 @media (max-width: 1200px) { 
  .about{
    padding-bottom: 50px;
  }
 .about .text h2 {
  font-size: 35px;
  line-height: normal;
}
.about .text p{
  font-size: 14px;
}
.about .text a {
  padding: 13px;
  font-size: 14px;
}
.choose .text h2 {
  font-size: 35px;
  line-height: normal;}
  .choose .text p{
    font-size: 14px;
  }
  .choose{
    padding-bottom: 80px;
  }
 }
 @media (max-width: 992px) { 
  .choose{
    padding: 50px 0px !important;
    padding-top: 20px !important;
  }
  .about .text p{
    line-height: auto;
  }
  .about .text {
    padding-left: 0px;
    text-align: center;
    margin-top: 50px;
}
  .image{
    text-align: center;
  }
  .banner img{
    display: none;
  }
  .choose .text{
    padding-left: 0px;
    text-align: center;
    margin-top: 50px;
  }
  .choose{
    padding-bottom: 0px !important;
  }
  .banner .inner-text p{
    font-size: 14px !important;
  }
  .inner-text h1{
    font-size: 30px;
  }
 }