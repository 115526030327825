.product-buying{
    padding: 99px 0px 349px 0px;
  }
  .product-buy-img{
    width: 350px;
    height: 437px;
    max-width: 100%;
    overflow: hidden;
    border-radius: 30px;
  }
  .product-buy-img img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
  .prodct-img-in{
     position: relative;
    }
  .prdct-img-scnd{
        position: absolute;
        content: "";
        top: 53%;
        left: 30%;
    }
  .product-buy-cont h2{
    font-size: 32px;
    margin-top: 47px;
  }
  .product-buy-p1{
    font-size: 1rem;
    margin-top: 19px;
  }
  .product-buy-cont p{
    line-height: 22px;
    color: #7D7D6C;
  }
  .product-buy-p2,
  .product-buy-p3{
    font-size: 15px;
    margin-top: 46px;
  }
  @media (max-width:1200px) { 
    .product-buy-img {
      width: 250px;
      height: 350px;
    }
    .product-buying .col-md-6{
      flex: 0 0 100%;
        max-width: 100%;
    }
  }
  @media (max-width:992px) {
    .product-buying{
      padding-bottom: 80px;
    }
    .prdct-img-scnd {
      position: relative;
      content: "";
      top: auto;
      left: auto;
  }
  .prodct-img-in{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .product-buy-in{
    text-align: center;
  }
   }
   @media (max-width:768px) {
    .product-buy-p2, .product-buy-p3 {
      font-size: 14px;
      margin-top: 20px;
  }
    .product-buying{
      padding: 50px 0px;
    }
    .product-buy-img{
      width: 40%;
    }
   }
  
   @media (max-width:480px) {
    .product-buy-img{
      height: 200px;
    }
   }
    
  