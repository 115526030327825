.gal-text h2{
  font-size: 32px;
  width: 370px;
  max-width: 100%;
  margin-bottom: 30px;
}
.gal-text p{
    margin-bottom: 30px;
    line-height: 22px;
    width: 500px;
    max-width: 100%;
}
.gal-img img{
 max-width: 100%;
}
.alrow{
    align-items: center;
}
.padingcont{
    padding-top: 80px;
    padding-bottom: 80px;
}
.gallerypage .gallery .gallery-in-Img{
    height: 320px;
    margin-bottom: 20px;
}
@media (max-width:1200px) { 
    .padingcont{
        padding-bottom: 0px !important;
    }
    .gallerypage .gallery .gallery-in-Img {
        height: 220px;
    }
}
@media (max-width:992px) { 
    .gal-text p{
        font-size: 14px;
        line-height: 25px;
    }
    .gallery{
        padding-top: 50px;
    }
   .padingcont .col-lg-6:last-child{
       order: -1;
   }
   .gal-img{
    text-align: center;
   }
   .gal-text{
    text-align: center;
    margin-top: 50px;
   }
   .gal-text h2{
    text-align: center;
    width: 100%;
   }
   .gal-text p{
    text-align: center;
    width: 100%;
   }
   .gallerypage .col-md-3{
    -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
   }
}
@media (max-width:768px) { 
    .gallerypage .col-md-3{
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
       }
}
@media (max-width:480px) { 
    .gallerypage .gallery .gallery-in-Img {
        height: auto;
    }
    .gallerypage .col-md-3{
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
       }
}