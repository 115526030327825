/* business */
.business{
    text-align: center;
    background-color: #E9EEF4;
    padding: 90px 0px;
}
.business-in{
    background-color: #2E4936;
    padding: 20px 25px;
    border-radius: 30px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}
.business-in .check{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #90F2B7;
    font-size: 30px;
    text-align: center;
}
.business-in-cont{
    width: calc(100% - 50px);
}
.business-in h4{
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize;
    color: #90F2B7;
    display: inline-block;
}
.business p{
    margin: 20px 0px 40px 0px;
}